'use strict';

import styled from 'styled-components';
import c from '../lib/classer';

import {graphql} from 'react-apollo';

import UserWidget from '../containers/user-widget';
import {Calendar, Close, UserRounded, ChartBar, Dashboard, Tag, Chat} from './svg/index';

import MainLink from './main-link';
import MainExternalLink from './main-external-link';
import Placeholder from "./placeholder";

import eventScheduleQuery from '../queries/event-schedule';

const Aside = styled.aside`
	flex-direction: column;
	flex-basis: 408px;
	flex-shrink: 0;
	background-color: white;
	display: flex;
	align-items: center;
	position: relative;
	min-height: calc( 100vh - 95px - 40px );

	.close-button {
		display: none;
	}

	h3 {
		margin: 10px 0 0 0;
	}

	hr {
		width: 100%;
		border: none;
		border-bottom: 1px solid rgb( 202, 202, 202 );
	}

	nav {
		display: flex;
		flex-direction: column;
		width: 280px;
		padding: 30px;
		position: relative;
		background-color: ${ props => props.theme.backgroundGray };
		flex-basis: 1;
		flex-shrink: 0;
		margin-top: 30px;
		margin-bottom: 30px;

		+ div {
		  margin: 0 30px;
		  max-width: 340px;
		}
	}

	@media (max-width: ${props => props.theme.breakpoints.big}px) {
		position: fixed;
		flex-basis: auto;
		flex-shrink: 0;
		flex-direction: column;
		justify-content: flex-start;
		z-index: 10000;
		right: calc( 100% + 15px );
		transition: right 0.4s ease-in-out;
		width: 300px;
		padding-bottom: 20px;
		box-shadow: 0 3px 15px rgb( 140, 140, 140 );
		background-color: white;
		top: 0;
		bottom: 0;
		overflow-y: scroll;

		nav {
		  background: transparent;
		}

		.close-button {
			display: inline;
			position: absolute;
			top: 20px;
			right: 20px;
			cursor: pointer;
			z-index: 20000;

			[fill] {
				fill: ${props => props.theme.almostBlack};
			}
		}

		nav {
			padding: 0;
			width: auto;
			flex-basis: auto;
			flex-grow: 0;
			margin-bottom: 20px;
		}

		&.expanded {
			right: calc( 100% - 300px );
		}
	}

	@media (min-width: 765px) and (max-width: 906px) {
		top: -114px;
	}
`;

@graphql(eventScheduleQuery)
export default class extends React.Component {
  render() {
    const {
      showResponsiveMenu,
      closeMenu,
      isCongressActive,
			isEducationActive,
			isTeacherActive,
			data
		} = this.props;

		let isTeacher = false;

		if (!data.loading && data.User) {
			isTeacher = data.User.isTeacher;
		}

		return (<Aside
			className={c({ expanded: showResponsiveMenu })}
			onClick={showResponsiveMenu ? closeMenu : () => { }}
		>
			<div className="close-button" onClick={showResponsiveMenu ? closeMenu : () => { }}><Close /></div>
			<nav>
				<UserWidget />
				<hr />
				{isTeacher && (
					<React.Fragment>
					  <h3>Voor docenten</h3>
					  <MainLink to="/docent" className={isTeacherActive ? 'active' : ''} exact><Dashboard />Opleidingen</MainLink>
					  <hr />
						<h3>Persoonlijk</h3>
					</React.Fragment>
				)}
				<MainLink to="/activiteiten" exact><ChartBar />Mijn activiteit{this.props.activityCount > 0 && <span className="notification">{this.props.activityCount}</span>}</MainLink>
				<MainLink to="/producten"><Dashboard />Mijn producten</MainLink>
				<MainLink className={isEducationActive ? 'active' : ''} to="/opleidingen"><Calendar />Mijn opleidingen</MainLink>
				<MainLink className={isCongressActive ? 'active' : ''} to="/congressen"><Calendar />Mijn congressen</MainLink>
				<MainLink to="/advies"><Chat />Mijn adviesdesk</MainLink>
				<hr />
				{/* <MainLink to="/interesses"><Tag className="tag" />Mijn interesses</MainLink> */}
				<MainLink to="/gegevens"><UserRounded className="user" />Mijn gegevens</MainLink>
				<hr />
				<MainExternalLink href="https://www.rendement.nl/klantenservice" target="_blank"><Chat />Contact</MainExternalLink>
			</nav>
			<Placeholder position="PH_MR_side" />
		</Aside>);
	}
}
