'use strict';

import EntityForm from '../entity-form';
import styled from 'styled-components';
import postcodeApi from '../../lib/postcode';

const Form = styled.div`
  div.form-key-postalCode input {
    width: 75px;
  }

  div.form-key-houseNumber input {
    width: 75px;
  }

  div.form-key-houseNumberSuffix input {
    width: 75px;
  }

  div.form-object-element > div.form-key-shippingAddress,
  div.form-object-element > div.form-key-billingAddress {
    > .form-element-label {
      font-size: 1.2em;
      font-weight: bold;
    }
  }

  div.form-object-element > div.form-key-billingAddress {
    display: none;
  }

  div.form-object-element > div.form-key-address {
    display: block;
  }

  &.billing-enabled {
    div.form-object-element > div.form-key-billingAddress {
      display: block;
    }
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.state = {
      billingAddressDisabled: null,
      value: props.value || {}
    };
  }

  handleChange(value) {
    const {
      billingAddressDisabled
    } = value;
    this.setState({
      billingAddressDisabled,
      value
    });
    return value;
  }

  handleBlur() {
    const prefill = (type, value) => {
      const oldValue = this.props.value[type] || {};
      const newValue = value[type] || {};
      const changed = (oldValue.postalCode !== newValue.postalCode) || (oldValue.houseNumber !== newValue.houseNumber);
      if (changed && (value[type].postalCode || '').match(/^[1-9][0-9]{3}[a-z]{2}$/i) && (newValue.houseNumber || '').match(/[1-9][0-9]*/)) {
        value = JSON.parse(JSON.stringify(value));
        return postcodeApi(value[type].postalCode, value[type].houseNumber).then(response => {
          const {street, city} = response;
          value[type] = {
            ...value[type],
            street,
            city
          };
          return value;
        }).catch(() => {
          return value;
        });
      }
      return Promise.resolve(value);
    };
    const value = JSON.parse(JSON.stringify(this.state.value));
    return prefill('shippingAddress', value).then(value => {
      return prefill('billingAddress', value);
    }).then(value => {
      if (this.props.onBlur) {
        return this.props.onBlur(value);
      }
      return value;
    });
  }

  render() {
    const {
      billingAddressDisabled
    } = this.state;

    const value = {...this.props.value};

    // Remove country code and non-digits from phone number.
    if (typeof value.phone === 'string') {
      if (value.phone.startsWith('0031')) {
        value.phone = '0' + value.phone.substring(4);
      }
      value.phone = value.phone.replace(/[^\d]/g, '');
    }

    let hideBilling = true;
    if (billingAddressDisabled !== null) {
      hideBilling = billingAddressDisabled;
    } else {
      hideBilling = value.billingAddressDisabled;
    }

    const schema = {...this.props.schema};
    if (hideBilling) {
      // schema.required = ['shippingAddress'];
      schema.properties.billingAddress.required = [];
    }

    return (<Form className={`${hideBilling ? '' : 'billing-enabled'}`}>
      <EntityForm
        schema={schema}
        value={value}
        handleSubmit={this.props.handleSubmit}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        loading={this.props.loading}
        messages={this.props.messages}
        maxWidth={550}
      />
    </Form>);
  }
}
