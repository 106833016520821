import gql from "graphql-tag";

module.exports = gql`query {
  User: currentUser {
    id
    subscriptions(order: [{
      field: "active",
      direction: DESC
    }, {
      field:"startDate",
      direction: DESC
    }]) {
      count
      items {
        id
        startDate
        endDate
        active
        title
        logo
        url
        isOwner
        subscriptionReceiverName
        subscriptionReceiverEmail
      }
    }
  }
}`;
