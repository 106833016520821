import EventEvaluationTeaser from './event-evaluation-teaser';
import EducationUpcomingSession from './education-upcoming-session';
import CertificateConditions from '../components/certificate-conditions';
import CertificateDownload from '../components/certificate-download';

export default ({
  eventHasPassed,
  hasEvaluation,
  finishedEvaluation,
  paid,
  participated,
  certificateToken,
  type
}) => {
  const evaluationCondition = !hasEvaluation || finishedEvaluation;
  // If this event is in the past and it has an evaluation that the user didnt finish yet, show that.
  if (hasEvaluation && !finishedEvaluation && eventHasPassed) {
    return <EventEvaluationTeaser/>;
  }

  // If this event is passed but the user didn't pay yet, don't show the payment CTA.
  if ((!certificateToken || !paid || !evaluationCondition || !participated) && eventHasPassed) {
    return <CertificateConditions {...{
      participated,
      finishedEvaluation,
      hasEvaluation,
      paid,
      type
    }}/>;
  }

  // If the user paid and the event is in the passed, show the certificate download button.
  if ((paid || type === 'in_company') && eventHasPassed) {
    return <CertificateDownload token={certificateToken}/>;
  }

  // By default, show nothing.
  return <EducationUpcomingSession/>;
};
