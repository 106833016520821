import styled from 'styled-components';
import { TransparentLink } from '../buttons';

const Container = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding-right: 0;
    margin-right: 0 !important;
  }
`;

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: 1000
    };
  }
  componentDidMount() {
    window.setMediaHeight = height => {
      this.setState({height});
    };
  }

  componentWillUnmount() {
    if (window.setMediaHeight === 'function') {
      delete window.setMediaHeight;
    }
  }

  static defaultProps = {
    title: 'Materiaal'
  };

  render() {
    const {id, document} = this.props;

    const ext = document.extension.toLowerCase().split('.').join('');
    let url = `/api/media/view-db/${document.documentToken}/${ext}`;
    const match = document.url && document.url.match(/^.+\/(?:uploads|eventdocumentscontainer)\/([^\/]+).*$/);
    if (match) {
      url = `/api/media/view/${match[1]}/${document.name}.${ext}`;
    }
    const hasPreview = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx', 'mp4'].indexOf(ext) >= 0;

    return (<Container>

      <h1>{document.name}</h1>

      <TransparentLink to={`/opleiding/${id}/materiaal`}>
        Ga terug naar het materiaal overzicht
      </TransparentLink>

      <div>
        {hasPreview && (
          <iframe width="100%" height={this.state.height} src={url} border="0" style={{border: 0}}></iframe>
        )}
        {!hasPreview && (
          <p>Er is geen voorbeeldweergave beschikbaar.</p>
        )}
      </div>

    </Container>);
  }
}
