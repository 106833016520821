import EventEvaluationTeaser from '../components/event-evaluation-teaser';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom';

@withRouter
@graphql(gql `query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    forms {
     items {
       id
       type
       remarks
       formIO
     }
   }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.EventScheduleData !== 'undefined') {
      const form = data.EventScheduleData.forms.items.filter(item => item.type === 'evaluation');
      if (form.length) {
        return {
          evaluation: {
            description: form[0].remarks ? form[0].remarks : "",
            id: data.EventScheduleData.id
          }
        }
      }
    }
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
export default class extends React.Component {
  render() {
    if (!this.props.evaluation) {
      return <div/>;
    }

    return (<EventEvaluationTeaser
      description={this.props.evaluation.description}
      uri={`/opleiding/${this.props.evaluation.id}/evaluatieformulier`}
    />);
  }
}
