import gql from "graphql-tag";

export default gql`query ($code: ID!) {
  currentUser {
    id
    initials
    lastName
  }
  TeacherEventSession (id: $code) {
    id
    start
    end
    eventName
    scheduleDataCode
    location {
      id
      code
      name
      street
      houseNumber
      houseNumberSupplement
      locationSupplement
      postalCode
      city
      cityCode
      countryCode
      routeUrl
    }
    teacherNote
    sessions (limit: 1000) {
      items {
        id
        start
        end
        eventName
        location {
          id
          code
          name
          street
          houseNumber
          houseNumberSupplement
          locationSupplement
          postalCode
          city
          cityCode
          countryCode
          routeUrl
        }
        participants {
          count
          items {
            id
            initials
            firstName
            insertion
            lastName
            companyName
            present
          }
        }
      }
    }
    status {
      items {
        id
        material
        booklist
        participants
        materialComment
        booklistComment
        participantsComment
      }
    }
    documents {
      count
      items {
        id
        extension
        file
        name
        source
        type
        url
      }
    }
  }
}`
