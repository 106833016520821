'use strict';

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import UserWidget from '../components/user-widget';

@graphql(
  gql`
    query {
      User: currentUser {
        id
        avatar {
          items {
            id
            data
          }
        }
        initials
        infix
        lastName
        companyName
      }
    }
  `,
  {
    props: ({ data: { User } }) => ({ User })
  }
)
export default class extends React.Component {
  handleLogout() {
    let accessToken = '';
    if (typeof localStorage !== 'undefined') {
      accessToken = localStorage.accessToken;
      delete localStorage.accessToken;
      delete localStorage.accessTokenExpires;
    }

    document.location = `/logout?token=${accessToken}`;
  }

  render() {
    if (this.props.User === null) {
      this.handleLogout();
    }
    if (!this.props.User || typeof this.props.User === 'undefined') {
      return (
        <UserWidget
          avatar={null}
          title={title}
          subTitle={subtitle}
          onClickLogout={this.handleLogout}
        />
      );
    }

    const { companyName, avatar } = this.props.User;

    const fullName = ['initials', 'infix', 'lastName']
      .filter(
        property =>
          typeof this.props.User[property] !== 'undefined' &&
          this.props.User[property] !== null &&
          this.props.User[property].length > 0
      )
      .map(property => this.props.User[property])
      .join(' ');

    let avatarData = '/assets/avatar.png';
    if (avatar && avatar.items.length > 0) {
      avatarData = avatar.items[0].data;
    }

    let accountType = 'Person';
    let payload = {};
    const jwtPayload = (localStorage.accessToken || '').split('.')[1];
    if (jwtPayload) {
      payload = JSON.parse(atob(jwtPayload));
      if (payload.accountType) {
        accountType = payload.accountType;
      }
    }

    let title = fullName;
    let subtitle = companyName;
    if (accountType === 'Company') {
      title = payload.fullName;
    }
    if (accountType === 'Department') {
      title = payload.company;
      subtitle = payload.fullName;
    }

    return (
      <UserWidget
        avatar={avatarData}
        title={title}
        subTitle={subtitle}
        onClickLogout={this.handleLogout}
      />
    );
  }
}
