'use strict';

import styled from 'styled-components';
import slugify from '../../lib/slugify';
import moment from '../../lib/moment';
import {Link} from 'react-router-dom';
import {ChevronCapsuleAnchor, CTACapsuleLink, CTACapsuleFaux} from '../buttons';
import {BlueChevronRight, Cog} from '../svg';
import { hasLicenses } from '../../lib/subscriptions';

import c from '../../lib/classer';

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;

  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.lightGray};
  }

  &:first-child {
    margin-top: -30px;
  }

  .icon-container {
    flex-basis: 110px;
    flex-grow: 0;
    flex-shrink: 0;

    display: flex;
    justify-content: space-around;
    align-items: center;
    overflow: hidden;

    > img {
      width: 71px;
      height: 71px;
    }
  }

  .body {
    flex-grow: 1;
    flex-shrink: 1;
    align-self: stretch;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${props => props.theme.breakpoints.small}px) {
    .body {
      flex-direction: column;
      .buttons {
        text-align: left !important;
        .button {
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  .body .buttons {
    text-align: right;
    .button {
      margin-bottom: 0px;
      a {
        white-space: nowrap;
      }
    }
  }

  h1, h1 > a {
    font-size: 24px;
    color: ${props => props.theme.almostBlack};
    margin-bottom: 6px;
    font-size: 24px;
    line-height: 28px;
    margin-left: 0;

    svg {
      height: 15px;
      cursor: pointer;
    }
  }

  p {
    margin-top: 0;
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 19px;
    color: ${props => props.theme.mediumDarkGray};
  }

  a, div.button>div {
    color: ${props => props.theme.activeBlue};
    margin-top: 10px;
    margin-right: 0;
    margin-bottom: 5px;
    text-decoration: none;
    font-weight: 900;
    flex-shrink: 0;
  }

  button {
    margin-right: 25px;
    align-self: center;
    flex-shrink: 0;
  }

  &.expired {
    h1, p {
      color: ${props => props.theme.expiredRed};
    }
  }

  &.future {
    opacity: 0.5;
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    flex-direction: column;
    align-items: flex-start;

    .icon-container {
      display: none;
    }

    h1 {
      font-size: 18px;
    }

    p {
      font-size: 13px;
    }

    a {
      margin: 0;
    }

    button {
      margin: 0;
      align-self: flex-start;
    }
  }
`;

const Title = styled.h1`
  position: relative;
  padding-right: 45px;
`;

const SettingsLinkContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  svg {
    fill: #777;
  }
`;

const completeDateFormat = 'D MMMM YYYY';

export default class extends React.Component {
  render() {
    const {
      id,
      active,
      startDate,
      endDate,
      title,
      logo,
      url,
      isOwner,
      subscriptionReceiverName,
      subscriptionReceiverEmail
    } = this.props;

    const expired = !active || moment(endDate).valueOf() < new Date();
    const future = startDate && moment(startDate).isAfter(moment());
    const editUrl = `/producten/abonnementen/${slugify(title)}/${id}`;
    const licenseUrl = `/producten/abonnementen/${slugify(title)}/${id}/licenties`;

    const Button = future ? CTACapsuleFaux : CTACapsuleLink;

    return (
      <Item key={id} className={c({expired, future})}>
        {logo && <div className="icon-container"><img src={logo} alt={title}/></div>}
        <div className="body">
          <div className="info">
            <Title>
              {isOwner && !future ? (
                <Link to={editUrl}>{title}</Link>
              ) : title}
            </Title>
            {!isOwner && (
              <p>
                U heeft kosteloos toegang tot de inhoud van dit abonnement, omdat u een licentie toegewezen heeft gekregen van uw collega:
                {' '}
                <span className="data-hj-suppress">
                  {subscriptionReceiverName} ({subscriptionReceiverEmail}).
                </span>
              </p>
            )}
            {future && (
              <p>
                Ingangsdatum: {moment(startDate).format('D MMMM YYYY')}
              </p>
            )}
            {/* <p>{ expired ?
              'Verlopen' :
              `startdatum: ${
                moment(startDate).format(completeDateFormat)
              }${
                parseInt(moment(endDate).format('YYYY'), 10) > (new Date()).getFullYear() + 10 ? '' :
              `, einddatum: ${
                moment(endDate).format(completeDateFormat)
              }`}`}
            </p> */}
          </div>
          <div className="buttons">
            {!expired && url && (
              <div className="button">
                <ChevronCapsuleAnchor href={url} target="_blank">
                  <span style={{color: 'white'}}>Ga naar {url.replace(/^https?...(?:www.)?([^/]+).*/, '$1')}</span>
                </ChevronCapsuleAnchor>
              </div>
            )}
            {isOwner && (
              <div className="button">
                <Button to={editUrl}>
                  <span style={{color: 'white'}}>Abonnement beheren</span>
                </Button>
              </div>
            )}
            <div className="button">
              {isOwner && hasLicenses(id) && (
                <Button to={licenseUrl}>
                <span style={{color: 'white'}}>Licenties beheren</span>
              </Button>
              )}
            </div>
          </div>
        </div>
        <div>
        </div>
      </Item>
    );
  }
}
