import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { InlineLink } from '../buttons';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  align-content: flex-start;

  input[type=checkbox] {
    margin-top: 5px;
  }

  .mailing {
    flex-basis: 50%;
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    div { padding-left: 20px; }
    h2 { display: inline; }
    p { margin-top: 5px; }
  }

  p.intro {
    margin-bottom: 30px;
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    .mailing {
      flex-basis: 100%;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {mailings} = this.props;

    return (<Container>
      <p className="intro">
        Hier kunt u aangeven waarover u e-mails van ons wilt ontvangen met meer informatie en aanbiedingen.
        {/* Het aan- en afmelden voor nieuwsbrieven kunt u doen via <InlineLink to="/producten/nieuwsbrieven">Mijn producten - Nieuwsbrieven</InlineLink>. */}
      </p>
      { mailings.map(
        ({enabled, title, description, id, toggle}, index) => (<label key={index} className="mailing">
          <input type="checkbox" checked={enabled} onChange={toggle}/>
          <div>
            <h2>{ title }</h2>
            <p>{ description }</p>
          </div>
        </label>)
      ) }
    </Container>);
  }
}
