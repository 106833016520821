import gql from "graphql-tag";

export default gql`query ($sort: OrderDirection) {
  listTeacherEventSession (order: [
    {field: "start", direction: $sort}
  ]) {
    count
    items {
      id
      scheduleData {
        id
        code
        description
        endDate
        startDate
        title
      }
      start
      end
      eventName
      location {
        id
        code
        name
        street
        houseNumber
        houseNumberSupplement
        locationSupplement
        postalCode
        city
        cityCode
        countryCode
        routeUrl
      }
      status {
        items {
          id
          material
          booklist
          participants
        }
      }
    }
  }
}`
