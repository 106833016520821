'use strict';

import React from 'react';
import TeacherSession from '../list-items/teacher-session';

export default class extends React.Component {
  render() {
    const {sessions, showYears} = this.props;

    let lastYear = null;

    return (
      <div>
        { sessions.length
          ? sessions.map( item => {
            let showYear;
            if (showYears && item.start) {
              const year = item.start.substring(0, 4);
              showYear = lastYear === year ? null : year;
              lastYear = year;
            }
            return (
              <React.Fragment key={item.id}>
                {showYear && <h2>{showYear}</h2>}
                <TeacherSession {...item} url={`/docent/sessie/${item.id}`} />
              </React.Fragment>
            );
          }) : (
            <p>
              Deze lijst bevat momenteel geen opleidingensessies die aan uw account zijn gekoppeld.
            </p>
          )
        }
      </div>
    );
  }
}
