import Subscription from '../list-items/subscription';
import ShowMoreTab from '../show-more-tab';
import settings from '../../settings';
import React from 'react';
import { InlineAnchor } from '../buttons';

const paginationLimit = settings.pagination.subscriptions;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: true
    };
  }

  showAll() {
    this.setState({limit: false});
  }

  render() {
    const {subscriptions, count} = this.props;
    const {limit} = this.state;

    return (<div>
      {subscriptions.length ? (
        limit ? subscriptions.slice(0, paginationLimit) : subscriptions).map(item => {
          return (<Subscription key={item.id} {...item}/>);
        }
      ) : (
        <React.Fragment>
          U heeft momenteel geen abonnementen. Klopt dit niet? Neem dan contact op met onze <InlineAnchor href="https://www.rendement.nl/klantenservice" target="_blank">klantenservice</InlineAnchor>.
        </React.Fragment>
      )
      }
      { limit && subscriptions.length > paginationLimit && <ShowMoreTab onClick={this.showAll.bind(this)}>Toon alle { count } abonnementen</ShowMoreTab> }
    </div>);
  }
}
