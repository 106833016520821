import withApolloLoader from '../../containers/with-apollo-loader';
import {userLicensesQuery, userLicensesTransform} from '../../queries/user-licenses';

import License from '../../containers/license-details';

export default withApolloLoader({
  query: userLicensesQuery,
  transform: userLicensesTransform
})(({licenses, userId, match: {params: {id: subscriptionId}}}) => {
  // Filter the list with the current product id.
  licenses = licenses.filter(({id}) => id === subscriptionId);

  const hasDomainRestriction = !licenses.length || (
    licenses[0].allowedDomains !== ''
    && !String(licenses[0].allowedDomains).includes('geenrestrictie.nl')
  );
  return (
    <div>
      <p>
        Op deze pagina heeft u als hoofdabonnementhouder een overzicht van alle gratis
        licenties die binnen dit abonnement vallen.
        Hieronder kunt u zelf nieuwe licenties toevoegen en beheren.
      </p>
      {hasDomainRestriction && (
        <p>
          Na het invullen van de gegevens ontvangt uw collega een e-mail om zich aan
          te melden. Deze gratis licentie geeft toegang tot hetzelfde product dat u
          ontvangt. De licentie is niet mogelijk als vervanging voor een betaald
          abonnement. U en uw collega’s werken bij dezelfde organisatie gevestigd op
          hetzelfde adres en hebben dezelfde e-mailextensie. Als het hoofdabonnement
          stopt, stoppen ook de gratis licenties.
        </p>
      )}
      {!hasDomainRestriction && (
        <p>
          Na het invullen van de gegevens ontvangt uw collega een e-mail om zich aan
          te melden. Deze gratis licentie geeft toegang tot hetzelfde product dat u
          ontvangt. De licentie is niet mogelijk als vervanging voor een betaald
          abonnement. Als het hoofdabonnement stopt, stoppen ook de gratis licenties.
        </p>
      )}
      <div>
        {licenses.map(license => <License userId={userId} license={license} key={license.id}/>)}
      </div>
    </div>
  );
});
