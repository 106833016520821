'use strict';
import {TransparentLink} from '../buttons';
import Table from '../table';
import Adviser from '../adviser';
import Rating from '../rating';

export default class extends React.Component {
  render() {
    const {requests} = this.props;

    if (!requests.length) {
      return <div><p>U heeft op dit moment geen afgeronde adviesvragen.</p></div>
    }

    return <div>
      <Table
        columns={['question', 'date', 'rating', 'adviser']}
        columnLabels={{
          question: 'Vraag',
          date: 'Datum',
          rating: 'Waardering',
          adviser: 'Adviseur'
        }}
        columnWidths={{
          question: 4,
          date: 1,
          rating: 1,
          adviser: 2
        }}
        items={requests.map(({title, date, rating, replies, id, url}) => ({
          key: id,
          question: <TransparentLink to={`/advies/${id}`}>{title}</TransparentLink>,
          date: date.format("DD-MM-YYYY"),
          rating: <Rating score={rating}/>,
          adviser: <Adviser
            adviser={replies.items.length ? {
              name: replies.items[replies.items.length - 1].name,
              image: ''
            } : {
              name: 'Rendement',
              image: ''
            }}
            />
        }))}
      />
    </div>
  }
}
