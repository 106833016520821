import gql from "graphql-tag";

module.exports = gql`query ($userSubscriptionId: ID!) {
  UserSubscription(id: $userSubscriptionId) {
    id
    title
    active
    isOwner
    startDate
    endDate
    billingAddress { city postalCode country street houseNumber houseNumberSuffix }
    shippingAddress { city postalCode country street houseNumber houseNumberSuffix }
    subscriptionReceiverName
    subscriptionReceiverEmail
  }

  User: currentUser {
    id
    companyName
    salutation
    lastName
    phone
    mail
    invoicemail
    eventMail
    functionName
    companyName
    address {
      id
      postalCode
      street
      houseNumber
      houseNumberSuffix
      city
    }
    billingAddress {
      id
      postalCode
      street
      houseNumber
      houseNumberSuffix
      city
    }
  }
}`;
