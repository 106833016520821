'use strict';

import styled from 'styled-components';
import { CTACapsuleLink, TransparentAnchor, TransparentLink } from '../buttons';

const Wrapper = styled.div`
  td {
    line-height: 1.6rem;
  }
  td:first-child {
    width: 200px;
  }

  td:nth-child(2) {
    color: ${props => props.theme.mediumGray};
  }
  table {
    margin-bottom: 14px;
  }
`;

export default class extends React.Component {
  render() {
    const value = { ...this.props.value };
    const accountType = this.props.accountType;

    // Remove country code and non-digits from phone number.
    if (typeof value.phone === 'string') {
      if (value.phone.startsWith('0031')) {
        value.phone = '0' + value.phone.substring(4);
      }
      value.phone = value.phone.replace(/[^\d]/g, '');
    }

    return (
      <Wrapper>
        <table>
          <tbody>
            {accountType === 'Person' && (
              <React.Fragment>
                <tr>
                  <td>Organisatie:</td>
                  <td className="data-hj-suppress">{value.companyName}</td>
                </tr>
                <tr>
                  <td>Functie:</td>
                  <td className="data-hj-suppress">{value.functionName}</td>
                </tr>
                <tr>
                  <td>Aanhef:</td>
                  <td className="data-hj-suppress">{value.salutation}</td>
                </tr>
                <tr>
                  <td>Voorletter(s):</td>
                  <td className="data-hj-suppress">{value.initials}</td>
                </tr>
                <tr>
                  <td>Tussenvoegsel(s):</td>
                  <td className="data-hj-suppress">{value.infix}</td>
                </tr>
                <tr>
                  <td>Achternaam:</td>
                  <td className="data-hj-suppress">{value.lastName}</td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td className="data-hj-suppress">{value.phone}</td>
                </tr>
                {/* <tr>
                  <td>E-mailadres facturatie:</td>
                  <td>{value.invoicemail}</td>
                </tr> */}
              </React.Fragment>
            )}
            {accountType === 'Company' && (
              <React.Fragment>
                <tr>
                  <td>Organisatie:</td>
                  <td>{value.fullName}</td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td>{value.phoneNumber || value.phone}</td>
                </tr>
                {/* <tr>
                  <td>E-mailadres facturatie:</td>
                  <td>{value.invoiceEmail || value.invoicemail}</td>
                </tr> */}
              </React.Fragment>
            )}
            {accountType === 'Department' && (
              <React.Fragment>
                <tr>
                  <td>Organisatie:</td>
                  <td>{value.company}</td>
                </tr>
                <tr>
                  <td>Afdeling:</td>
                  <td>{value.fullName}</td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td>{value.phoneNumber || value.phone}</td>
                </tr>
                {/* <tr>
                  <td>E-mailadres facturatie:</td>
                  <td>{value.invoiceEmail || value.invoicemail}</td>
                </tr> */}
              </React.Fragment>
            )}
          </tbody>
        </table>
        {/* {accountType === 'Person' && (
          <React.Fragment>
            <p>
              Uw verzend- en facturatiegegevens kunt u per abonnement terugvinden en wijzigen bij{' '}
              <TransparentLink to="/producten">Mijn producten</TransparentLink>.
            </p>
            <CTACapsuleLink to="/gegevens/wijzigen">Gegevens wijzigen</CTACapsuleLink>
          </React.Fragment>
        )}
        {accountType !== 'Person' && (
          <React.Fragment>
            <p>
              Indien u uw gegevens wenst te wijzigen neem contact op met onze{' '}
              <TransparentAnchor href="https://www.rendement.nl/formulier/klantenservice/contactformulier">
                klantenservice
              </TransparentAnchor>
              ..
            </p>
          </React.Fragment>
        )} */}
      </Wrapper>
    );
  }
}
