'use strict';

import withApolloLoader from '../with-apollo-loader';

import TeacherSessions from '../../components/screens/teacher-sessions';

import teacherSessionsQuery from '../../queries/teacher-sessions';

export default withApolloLoader({
  query: teacherSessionsQuery,
  variables: {
    sort: 'DESC'
  },
  transform: ({listTeacherEventSession}) => {
    const includedIds = [];
    const sessions = listTeacherEventSession.items.filter((v, i, a) => {
      if (includedIds.indexOf(v.scheduleData.id) >= 0) {
        return false;
      }
      includedIds.push(v.scheduleData.id);
      return true;
    }).map(v => {
      v.eventName = v.scheduleData.title;
      v.start = v.scheduleData.startDate;
      return v;
    });

    return {sessions, showYears: true};
  },
  LoaderComponent: () => <div>Trainingssessies worden ingeladen...</div>
})(TeacherSessions);
