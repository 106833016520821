import gql from 'graphql-tag';
import filesize from 'filesize';
import {withRouter, useParams} from 'react-router-dom';

import withApolloLoader from '../with-apollo-loader';

import {compose} from '../../lib/functions';

import moment, {ISODATEFORMAT} from '../../lib/moment';

import eventMaterialQuery from '../../queries/event-material';

import EventMaterial from '../../components/screens/event-material';

export default compose(withRouter, withApolloLoader({
  query: eventMaterialQuery,
  getVariables: ({match: {params: {id}}}) => ({id}),
  LoaderComponent: () => <div>Het materiaal wordt ingeladen...</div>,
  transform: ({EventScheduleData}) => {
    if (!EventScheduleData || !EventScheduleData.documents.items) {
      return null;
    }

    const {
      documentRemarks,
      documents: {items: documents = []} = {},
      loggedInParticipant: {participations: {items: participations}}
    } = EventScheduleData;

    const formatDocument = ({
      document,
      document: {size, extension = ''}
    }) => ({
      ...document,
      size: filesize(size),
      extension: extension.replace('.', '')
    });

    // No easy and clean way to do this...
    const isCourse = document.location.pathname.split('/')[1] === 'opleiding';
    const emptyText = isCourse
      ? 'Vanaf een week voor de training vindt u hier het cursusmateriaal. Zodra het cursusmateriaal beschikbaar is, krijgt u hiervan bericht per mail.'
      : 'Vanaf een week voor het congres vindt u hier het congresmateriaal. Zodra het congresmateriaal beschikbaar is, krijgt u hiervan bericht per mail.';

    const eventMaterial = {
      description: documentRemarks || emptyText,
      documents: documents.map(formatDocument).sort((a, b) => a.name.localeCompare(b.name))
    };

    const material = [eventMaterial];

    return {material};
  }
}))(({children, material, match: {params: {id}}}) => (
  <EventMaterial id={id} material={material}>
    {children}
  </EventMaterial>
));
