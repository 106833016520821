import Document from '../document';
import Block from '../block';
import styled from 'styled-components';
import { TransparentAnchor } from '../buttons';

const Container = styled.div`
  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding-right: 0;
    margin-right: 0 !important;
  }
`;

const List = styled.ul`
  margin: 20px;
  padding: 0;

  > li {
    list-style-type: none;
  }
`;

export default class extends React.Component {
  static defaultProps = {
    title: 'Materiaal'
  };

  render() {
    const zipLink = documents => {
      const parts = documents.filter(({url}) => url).map(({url, name, extension}) => {
        const ext = extension.toLowerCase().split('.').join('');
        const prefix = name.match(/\.[a-z]{2,4}$/) ? '' : `.${ext}`;
        return `/${url.replace(/^.+\/(?:uploads|eventdocumentscontainer)\/([^\/]+).*$/, '$1')}/${name}${prefix}`;
      });
      if (parts.length > 1) {
        return '/api/media/download-zip' + parts.join('');
      }
    };

    const {
      material,
      title,
      id
    } = this.props;

    return (<Container>
      {material.map(({
        title: materialTitle = title,
        description,
        documents
      }) => <Block key={materialTitle} title={materialTitle}>
        <p>{description}</p>
        <List>
          {documents.map(doc => (<li key={doc.id}>
            <Document {...doc} id={id}/>
          </li>))}
        </List>
        {zipLink(documents) && (
          <TransparentAnchor href={zipLink(documents)}>Download alles in een ZIP</TransparentAnchor>
        )}
      </Block>)}
    </Container>);
  }
}
