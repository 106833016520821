'use strict';

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

import styled from 'styled-components';
import moment from '../../lib/moment';
import {Link} from 'react-router-dom';

import {ChevronCapsuleAnchor} from '../buttons';

const Item = styled.label`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
  border-bottom: 1px solid ${props => props.theme.lightGray};
	padding: 30px 0;

  &:first-child {
    margin-top: -20px;
  }

  > div {
    flex-grow: 1;
    display: flex;
  }

  .icon-container {
    margin-right: 15px;

    input {
      margin-top: 4px;
    }
  }

  .body {
    flex-grow: 1;
  }

  h1 {
    font-size: 20px;
    color: ${props => props.theme.almostBlack};
    font-weight: 400;
    margin-top: 0;
  }

  p {
    font-size: 16px;
		line-height: 19px;
    color: ${props => props.theme.mediumDarkGray};
		margin: 0;
  }

  a {
    margin-left: 25px;
    margin-right: 25px;
		flex-shrink: 0;
    margin-top: 0;
    margin-bottom: 0;
    align-self: center;
  }

	@media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding: 15px 0;
    flex-direction: column;

		h1 {
			font-size: 18px;
		}

		p {
			font-size: 13px;
		}

		a {
      align-self: flex-start;
      margin-top: 20px;
			margin-bottom: 5px;
      margin-left: 30px;
		}
	}
`;

export default class extends React.Component {
  render() {
    const {action, id, description, enabled, title, url}  = this.props;
    return (
      <Item key={id}>
        <div>
          <div className="icon-container"><input type="checkbox" checked={enabled} onChange={action(this)}/></div>
          <div className="body">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
        {/* {url && <ChevronCapsuleAnchor href={url} target="_blank">Lees nieuwsbrief</ChevronCapsuleAnchor>} */}
      </Item>
    );
  }
}
