'use strict';

import withApolloLoader from '../with-apollo-loader';

import TeacherSessions from '../../components/screens/teacher-sessions';

import teacherSessionsQuery from '../../queries/teacher-sessions';

export default withApolloLoader({
  query: teacherSessionsQuery,
  variables: {
    sort: 'ASC'
  },
  transform: ({listTeacherEventSession}) => {
    const includedIds = [];
    const sessions = listTeacherEventSession.items.filter((v, i, a) => {
      if (includedIds.indexOf(v.scheduleData.id) >= 0) {
        return false;
      }
      includedIds.push(v.scheduleData.id);
      return true;
    }).map(v => {
      v.eventName = v.scheduleData.title;
      if (v.start && v.scheduleData.startDate) {
        const [date] = v.scheduleData.startDate.split(' ');
        const [, time] = v.start.split(' ');
        v.start = `${date} ${time}`;
      }
      return v;
    });

    return {sessions};
  },
  LoaderComponent: () => <div>Trainingssessies worden ingeladen...</div>
})(TeacherSessions);
