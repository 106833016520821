'use strict';

import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import moment from '../../lib/moment';
import AddressTable from '../address-table';
import { CTACapsuleLink, SubduedCapsuleAnchor } from '../buttons';

const Wrapper = styled.div`
  header {
    margin-bottom: 20px;

    h1 {
      padding-bottom: 0 !important;
    }

    a {
      margin-left: 40px;
    }
  }

  > div {
    p,
    h2,
    td {
      color: ${props => props.theme.almostBlack};
    }
  }

  > div > p:first-child {
    margin-top: 0;
  }

  div.address-tables {
    margin-bottom: 45px;
  }
`;

const FlexList = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  > *:not(:first-child) {
    margin-left: ${({ spacing }) => spacing}px;
  }
`;

@withRouter
export default class extends React.Component {
  render() {
    const {
      title,
      url,
      active,
      user: { companyName, billingAddress: userBilling, shippingAddress: userShipping },
      billingAddress: productBilling,
      shippingAddress: productShipping,
      startDate,
      endDate,
      isOwner,
      match: {
        params: { id }
      }
    } = this.props;

    const subscriptionId = id.split('---')[1];
    const billingAddress =
      productBilling && productBilling.city
        ? productBilling
        : userBilling || {
            postalCode: '',
            houseNumber: '',
            houseNumberSuffix: '',
            street: '',
            locality: ''
          };
    const shippingAddress =
      productShipping && productShipping.city
        ? productShipping
        : userShipping || {
            postalCode: '',
            houseNumber: '',
            houseNumberSuffix: '',
            street: '',
            locality: ''
          };

    // Temporary subscriptions have an id in uuid format.
    // See mail mail from Jos Groenendaal, April 6th 2023.
    const isTemporary = !!id.match(/---[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/);

    return (
      <Wrapper>
        <div>
          {isTemporary ? (
            <p>
              Startdatum: {moment(startDate).format('D MMMM YYYY')}
            </p>
          ) : (
            <p>
              Huidige abonnementsperiode: {moment(startDate).format('D MMMM YYYY')} tot{' '}
              {parseInt(moment(endDate).format('YYYY'), 10) > new Date().getFullYear() + 10
                ? 'wederopzegging'
                : moment(endDate).format('D MMMM YYYY')}
            </p>
          )}
          <p>Abonnementsnummer: {subscriptionId}</p>
          {/* <FlexList spacing={150} className="address-tables">
            <div className="address-container">
              <h2>Verzendgegevens</h2>
              <AddressTable
                {...{
                  ...(companyName ? { companyName } : {}),
                  postalCode: shippingAddress.postalCode,
                  houseNumber: `${shippingAddress.houseNumber}${
                    shippingAddress.houseNumberSuffix || ''
                  }`,
                  street: shippingAddress.street,
                  locality: shippingAddress.city
                }}
              />
            </div>
            <div className="address-container">
              <h2>Facturatiegegevens</h2>
              <AddressTable
                {...{
                  ...(companyName ? { companyName } : {}),
                  postalCode: billingAddress.postalCode,
                  houseNumber: `${billingAddress.houseNumber}${
                    billingAddress.houseNumberSuffix || ''
                  }`,
                  street: billingAddress.street,
                  locality: billingAddress.city
                }}
              />
            </div>
          </FlexList> */}
          <FlexList spacing={15}>
            {/* {active && isOwner && (
              <CTACapsuleLink to={`${url}/wijzigen`}>Gegevens wijzigen</CTACapsuleLink>
            )} */}
            {active && isOwner && (
              <SubduedCapsuleAnchor
                href="https://www.rendementuitgeverij.nl/#veelgestelde-vragen"
                target="_blank"
              >
                Service &amp; Contact
              </SubduedCapsuleAnchor>
            )}
          </FlexList>
        </div>
      </Wrapper>
    );
  }
}
