'use strict';

import withApolloLoader from '../with-apollo-loader';
import moment, { ISODATEFORMAT } from 'moment';

import TeacherSessionMain from '../../components/screens/teacher-session-main';

import teacherSessionQuery from '../../queries/teacher-session';

export default withApolloLoader({
  query: teacherSessionQuery,
  getVariables: ({match: {params: {code}}}) => ({code}),
  getChildProps: ({match: {params: {code}}}) => ({code}),
  transform: ({currentUser, TeacherEventSession}) => {
    const session = TeacherEventSession;
    session.sessions.items.sort((a, b) => {
      const v1 = a.start ? moment(a.start, ISODATEFORMAT).unix() : 0;
      const v2 = b.start ? moment(b.start, ISODATEFORMAT).unix() : 0;
      return v1 === v2 ? 0 : v1 - v2;
    });
    return {session, currentUser};
  },
  LoaderComponent: () => <div>Trainingssessie wordt ingeladen...</div>
})(TeacherSessionMain);
