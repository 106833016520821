import {withRouter} from "react-router-dom";
import {graphql} from "react-apollo";
import gql from "graphql-tag";
import FormIO from '../../components/form-io';
import EducationGeneralQuery from '../../queries/education-general';
import ContentHeading from '../../components/content-heading';

@withRouter
@graphql(gql`query($id: ID!) {
  currentUser {
    id
  }
  EventScheduleData(id: $id) {
    id
    loggedInParticipant {
      id
      registrationNumber
      finishedEvaluation
      finishedPreparation
    }
    forms {
      items {
        id
        type
        formIO
      }
    }
		eventSchedule {
      event {
        id
        group {
          id
          type
        }
      }
      id
    }
  }
}`, {
  props: (props) => {
    const {data} = props;
    const formType = props.ownProps.match.url.match(/evaluatieformulier/) ? 'evaluation' : 'preparation_assignment';

    if (!data.loading && typeof data.EventScheduleData !== 'undefined') {
      const formMapping = {
        preparation_assignment: 'Voorbereidingsopdracht',
        evaluation: 'Evaluatieopdracht'
      };

      const type = formMapping[formType];

      const checkMapping = {
        evaluation: 'finishedEvaluation',
        preparation_assignment: 'finishedPreparation'
      };

      // Check if we have already filled in this form. Redirect if this is the case (see render).
      const formDone = data.EventScheduleData.loggedInParticipant[checkMapping[formType]];
      if (formDone) {
        return {
          formDone,
          type
        };
      }

      const educationMapping = {
        course: "de opleiding",
        education: "de opleiding",
        congress: "het congres"
      };

      const form = (data.EventScheduleData.forms.items.filter(form => form.type === formType) || []).shift();

      return {
        educationType: educationMapping[data.EventScheduleData.eventSchedule.event.group.type],
        form,
        type,
        formDone: false,
        EventScheduleDataId: data.EventScheduleData.id,
        userEventId: data.EventScheduleData.loggedInParticipant.id,
        userId: data.currentUser.id,
        registrationNumber: data.EventScheduleData.loggedInParticipant.registrationNumber
      }
    }
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
@graphql(gql`mutation ($userEventId: ID!, $data: UserEventUpdateInput!) {
  updateUserEvent(id: $userEventId, input: $data) { id }
}`, {
  name: 'updateUserEvent'
})
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(e) {
    if (e.data === 'submitFormIO') {
      const {userEventId, EventScheduleDataId} = this.props;

      const variables = {
        userEventId,
        data: {}
      };

      const formMapping = {
        preparation_assignment: 'finishedPreparation',
        evaluation: 'finishedEvaluation'
      };

      let type = 'preparation_assignment';
      if (typeof this.props.form !== 'undefined' && typeof this.props.form.type !== 'undefined') {
        type = this.props.form.type;
      }

      variables.data[formMapping[type]] = true;

      this.props.updateUserEvent({
        variables,
        refetchQueries: [{
          query: EducationGeneralQuery,
          variables: {id: EventScheduleDataId}
        }],
        awaitRefetchQueries: true
      }).then(() => {
        this.props.history.push(`/opleiding/${EventScheduleDataId}`);
      });
    }
  }

  render() {
    if (!this.props.form) {
      if (this.props.formDone) {
        return <div>
          <ContentHeading title={this.props.type}>
            <p style={{marginLeft:'40px'}}>U heeft dit formulier eerder al ingevuld en ingezonden.<br/></p>
          </ContentHeading>
        </div>;
      }

      return null;
    }

    const {
      type,
      educationType,
      form: {formIO},
      registrationNumber,
      userId
    } = this.props;

    return <FormIO
      title={type}
      backTitle={`Terug naar de vorige pagina`}
      url={formIO}
      onSubmit={this.handleSubmit}
      data={{
        registrationNumber,
        userId
      }}
    />;
  }
}
