'use strict';

import styled from 'styled-components';
import moment from '../../lib/moment';
import sanitize from '../../lib/sanitize';
import {Download, ELearning, QuestionMark, Heart} from '../svg/index';
import Tags from '../tags';

const categories = {
  FAVORITE: {
    description: 'Opgeslagen in uw <strong>favorieten</strong>.',
    IconComponent: Heart
  },
  DOWNLOAD: {
    description: 'Item gedownload op <strong>Rendement.nl</strong>',
    IconComponent: Download
  },
  ELEARNING: {
    description: '<strong>E-learning</strong> gestart.',
    IconComponent: ELearning
  },
  ADVIES_DESK: {
    description: 'Antwoord op uw vraag in <strong>Adviesdesk</strong>.',
    IconComponent: QuestionMark
  }
};

const Item = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;

  &:link, &:visited {
    text-decoration: none;
  }

  :not(:last-child) {
    margin: 40px 0;
  }

  strong {
    font-family: Karla, sans-serif;
    color: black;
  }

  div.icon-container {
    display: flex;
    flex-basis: 50px;
    flex-shrink: 0;
    justify-content: space-around;
    align-items: center;
    margin-right: 25px;

    div.circle {
      width: 50px;
      height: 50px;
      background-color: white;
      border: 3px solid ${props => props.theme.actionOrange};
      border-radius: 50%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      box-sizing: border-box;

      svg {
        [fill]:not([fill=none]) { fill: ${props => props.theme.actionOrange}; }
      }
    }
  }

  div.content {
    flex-grow: 1;

    h1 {
      font-size: 18px;
      margin-top: 5px;
      margin-bottom: 5px;

      .title-body {
        color: ${props => props.theme.actionBlue};
        max-width: 610px;
      }

      span.moment {
        color: ${props => props.theme.almostBlack};
        float: right;
        font-size: 16px;
        font-weight: 400;

        @media (max-width: ${props => props.theme.breakpoints.big}px) {
          float: none;
          display: block;
          margin-top: 5px;
        }
      }
    }

    p {
      margin-top: 0;
      color: ${props => props.theme.mediumGray};
      margin-bottom: 0;
    }
  }
`;

export default class extends React.Component {
  render() {
    const {category, id, title, created, url, type, meta} = this.props;
    let {description, IconComponent} = categories[category];
    if (category === 'ELEARNING' && meta === 'finished') {
      description = '<strong>E-learning</strong> afgerond.'

    }
    const tags = type && type.length > 0 ? [
      String(type).toLowerCase() === 'story' ? 'Verdiepingsartikel' : type
    ] : [];

    const fallbackName = url.split('/').pop().split('+').join(' ');

    const timezone = (new Date()).getTimezoneOffset() === -120 ? '+02:00' : '+01:00';
    const date = created.replace('Z', timezone);

    return (
      <Item key={id} href={url} target={url.indexOf('://') < 0 ? '_self' : '_blank'}>
        <div className="icon-container">
          <div className="circle">
            <IconComponent/>
          </div>
        </div>
        <div className="content">
          <h1>
            {title ? (
              <span className="title-body" dangerouslySetInnerHTML={sanitize(title)}/>
            ) : (
              <span className="title-body">
                {fallbackName.length > 32 ? fallbackName.substring(0, 30).trim() + '...' : fallbackName}
              </span>
            )}
            <Tags tags={tags}/>
            <span className="moment">{ moment(date).fromNow() }</span>
          </h1>
          <p dangerouslySetInnerHTML={sanitize(description)}/>
        </div>
      </Item>
    );
  }
}
