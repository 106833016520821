'use strict';

import styled from 'styled-components';
import moment, { ISODATEFORMAT } from 'moment';
import { Mutation, withApollo } from 'react-apollo';
import gql from 'graphql-tag';
import Block from './../../components/block';
import { CapsuleButton, TransparentAnchor } from './../../components/buttons';
import fetch from 'isomorphic-fetch';
import download from 'downloadjs';
import EventSteps from '../event-steps';
import Dialog from '../dialog';
import React from 'react';
import EducationUpcomingSession from '../education-upcoming-session';
import teacherSessionQuery from '../../queries/teacher-session';
import stats from '../../stats';
import EducationDateLocations from '../education-date-locations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: flex-start;
  padding-right: 40px;

  > * {
    flex-basis: 48%;
    margin-right: 2%;

    &:nth-child(even) {
      margin-left: 2%;
      margin-right: 0;
    }

    &.participant-list {
      flex-basis: 100%;
    }
  }

  @media (max-width: ${props => props.theme.breakpoints.big}px) {
    padding-right: 0;
    margin-right: 0 !important;

    > * {
      flex-basis: 100%;
      margin-right: 0;
      margin-left: 0;

      &:nth-child(even) {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  .general-information {
    margin-top: 25px;
  }

  .participant-list table {
    width: 100%;

    thead {
      font-weight: bold;
      tr td {
        border-bottom: 1px solid ${props => props.theme.darkGray};
      }
    }
    th {
      text-align: left;
    }
    tr {
      width: 100%;

      td {
        padding: 5px;
        border-bottom: 1px solid ${props => props.theme.lightGray};
      }
    }
    tr.button-row td {
      border: none;
    }
  }

  textarea {
    clear: both;
    width: 100%;
    height: 70px;
    margin-bottom: 11px;
  }
`;

const DialogWrapper = styled.div`
  h1 {
    color: ${props => props.theme.activeBlue};
  }
  p {
    color: ${props => props.theme.darkGray};
  }
`;

@withApollo
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      participants: props.session.sessions.items.reduce((prev, {id, participants}) => ({
        ...prev,
        [id]: participants.items
      }), {}),
      participantsChanged: false,
      participantsSaving: false,
      showConfirm: false,
      showSaved: false,
      note: ''
    };
  }

  render() {
    const { session, code, refetch, client, currentUser } = this.props;
    const { participants: participantsFor, participantsSaving } = this.state;

    // Participants are split per day. Use list of first day for listing participants.
    let participants = [];
    if (Object.keys(participantsFor).length) {
      const key = Object.keys(participantsFor)[0];
      participants = participantsFor[key];
    }

    const status = session.status.items[0];
    const submitted = status && status.participants;

    const properties = [];
    if (session.location) {
      properties.push({ label: 'Locatie', value: session.location.name });
    }
    if (session.start) {
      properties.push({ label: 'Start', value: moment(session.start, ISODATEFORMAT).format('DD-MM-YYYY') });
    }
    if (session.end) {
      properties.push({ label: 'Eind', value: moment(session.end, ISODATEFORMAT).format('DD-MM-YYYY') });
    }

    const exportParticipants = () => {
      fetch('/download-participants/' + code, {
        headers: {
          Authorization: 'Bearer ' + localStorage.accessToken
        }
      }).then(res => res.blob()).then(blob => {
        download(blob, 'deelnemers.xlsx', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
      });
    };

    const isMultipleDays = session.sessions.items.length > 1;
    const sessionsSorted = session.sessions.items.sort((a, b) => {
      return a.start.localeCompare(b.start);
    });
    const futureSessions = sessionsSorted.filter(({start}) => {
      return moment(start).unix() > moment().unix();
    });
    const nextSession = futureSessions.length
      ? futureSessions[0]
      : (
        isMultipleDays ? null : session.sessions.items[0]
      );

    const saveParticipantsDay = (code, participants, attempt = 1) => {
      return client.mutate({
        mutation: gql`mutation SetAttendance (
          $session: String!,
          $present:[String]!,
          $absent: [String]!
        ) {
          updateAttendance(session: $session, present: $present, absent:$absent)
        }`,
        variables: {
          session: code,
          present: participantsFor[code].filter(p => p.present).map(p => p.id.split('/')[0]),
          absent: participantsFor[code].filter(p => !p.present).map(p => p.id.split('/')[0])
        }
      }).then(data => {
        const success = data.data.updateAttendance.statusCode < 300;
        if (!success && attempt <= 3) {
          const delay = new Promise(resolve => setTimeout(resolve, 100));
          return delay.then(() => {
            attempt = attempt + 1;
            return saveParticipantsDay(code, participants, attempt);
          });
        }
        return {
          response: data.data.updateAttendance,
          code,
          attempt
        };
      });
    };

    const saveParticipants = (participantsFor) => {
      return Promise.all(Object.keys(participantsFor).map(code => {
        return saveParticipantsDay(code, participantsFor[code]);
      })).then(results => {
        const success = results.every(r => r.response.statusCode < 300);
        const logData = {
          success,
          event: session.eventName,
          days: results.map(result => ({
            code: result.code,
            date: session.sessions.items.find(({id}) => id === result.code).start.replace(/^([0-9]{4}).([0-9]{2}).([0-9]{2}).+$/, '$1-$2-$3'),
            participants: participantsFor[result.code].map(p => ({
              id: p.id,
              present: p.present,
              initials: p.initials,
              lastName: p.lastName
            })),
            response: result.response,
            attempt: result.attempt
          }))
        };
        client.mutate({
          mutation: gql`mutation CreateLog (
            $log: String!,
            $severity: String!,
            $data: JSON!,
            $created: Int!
          ) {
            createLog(input: {
              log: $log,
              severity: $severity,
              data: $data,
              created: $created
            }) {
              id
            }
          }`,
          variables: {
            log: 'attendance',
            severity: success ? 'info' : 'error',
            data: logData,
            created: ~~(Date.now() / 1e3)
          }
        });
      });
    };

    return (
      <Mutation mutation={gql`mutation ($session: String!, $note: String!, $scheduleData: String!) {
        submitParticipants(session: $session, note: $note, scheduleData: $scheduleData)
      }`}>
        {(submitParticipants, { }) => (
          <Wrapper>
            <div className="general-information">
              <Block title="Algemene informatie">
                <div className="teacher-note">
                  <div dangerouslySetInnerHTML={{ __html: session.teacherNote }}/>
                </div>
                <div style={{marginTop: '25px'}}>
                  <p><strong>Te verrichten acties:</strong></p>
                  <EventSteps
                    steps={[{
                      done: status && status.booklist,
                      label: 'Boekenlijst uploaden'
                    }, {
                      done: status && status.material,
                      label: 'Opleidingsmateriaal uploaden'
                    }, {
                      done: status && status.participants,
                      label: 'Aanwezigheid deelnemers registreren'
                    }]}
                  />
                </div>
              </Block>
              <EducationDateLocations
                eventSessions={session.sessions.items}
                locationInfo=""
                routeUrl=""
              />
            </div>
            {nextSession && (
              <EducationUpcomingSession
                title={isMultipleDays ? "Eerstvolgende bijeenkomst" : "Klassikale bijeenkomst"}
                start={nextSession.start}
                end={nextSession.end}
                eventTeacher={{
                  name: `${currentUser.initials} ${currentUser.lastName}`
                }}
                locationInfo={nextSession.location ? nextSession.location.name : ''}
                postalCode={nextSession.location ? nextSession.location.postalCode : ''}
                address={nextSession.location ? nextSession.location.street : ''}
                city={nextSession.location ? nextSession.location.city : ''}
                routeUrl={nextSession.location ? nextSession.location.routeUrl : ''}
              />
            )}
            <div className="participant-list">
              <Block title="Deelnemers">
                {participants.length === 0 && (
                  <p>Er zijn nog geen deelnemers bekend voor deze sessie.</p>
                )}
                {participants.length > 0 && (
                  <React.Fragment>
                    <table>
                      <thead>
                      <tr>
                          <th>Achternaam</th>
                          <th>Tussenvoegsel</th>
                          <th>Voorletters</th>
                          <th colSpan={session.sessions.items.length}>Aanwezig?</th>
                        </tr>
                        {session.sessions.items.length > 1 && (
                          <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            {session.sessions.items.map(({id, start}) => (
                              <th key={id}>{start.replace(/^[0-9]{4}.([0-9]{2}).([0-9]{2}).+$/, '$2/$1').replace(/(?:^0|([^\d])0)/g, '$1')}</th>
                            ))}
                          </tr>
                        )}
                      </thead>
                      <tbody>
                        {participants.sort((a, b) => {
                          return String(a.lastName).toLowerCase().localeCompare(String(b.lastName).toLowerCase());
                        }).map((participant, index) => (
                          <tr key={participant.id}>
                            <td className="data-hj-suppress">{participant.lastName || null}</td>
                            <td className="data-hj-suppress">{participant.insertion || null}</td>
                            <td className="data-hj-suppress">{participant.initials || null}</td>
                            {session.sessions.items.map(({id}) => {
                              const participantDay = participantsFor[id].filter(({id}) => id.split('/')[0] === participant.id.split('/')[0])[0];
                              return (
                                <td key={id}>
                                  {participantDay && (
                                    <input
                                      type="checkbox"
                                      checked={participantDay.present}
                                      disabled={submitted}
                                      onChange={() => {
                                        const cloned = JSON.parse(JSON.stringify(participantsFor));
                                        cloned[id] = cloned[id].map(item => {
                                          if (item.id === participantDay.id) {
                                            item.present = !item.present;
                                          }
                                          return item;
                                        });
                                        this.setState({
                                          participants: cloned,
                                          participantsChanged: true,
                                          participantsSaving: false
                                        })
                                      }}
                                    />
                                  )}
                                </td>
                              );
                            })}
                          </tr>
                        ))}
                        <tr className="button-row">
                          <td colSpan={4}>
                            <TransparentAnchor onClick={exportParticipants}>
                              Deelnemerslijst downloaden
                            </TransparentAnchor>
                            {' '}
                            (op deze lijst staan ook de bedrijfsnamen)
                          </td>
                          <td colSpan={session.sessions.items.length}>
                            {!submitted && (
                              <CapsuleButton
                                type="button"
                                disabled={participantsSaving}
                                onClick={() => {
                                  this.setState({participantsSaving: true});
                                  saveParticipants(participantsFor).then(() => {
                                    return client.query({
                                      query: teacherSessionQuery,
                                      variables: {
                                        code
                                      },
                                      fetchPolicy: 'network-only'
                                    });
                                  }).then(() => {
                                    // Small timeout for visual response.
                                    return new Promise(resolve => setTimeout(resolve, 400));
                                  }).then(() => {
                                    this.setState({
                                      participantsChanged: false,
                                      participantsSaving: false,
                                      showSaved: true
                                    })
                                  }).catch((err) => {
                                    console.error(err);
                                    alert('Er is een fout opgetreden bij het opslaan.');
                                    this.setState({
                                      participantsSaving: false
                                    })
                                  });
                                }}
                              >
                                Opslaan
                              </CapsuleButton>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </React.Fragment>
                )}
              </Block>
              <Block title={submitted ? 'Deelnemers' : 'Deelnemers bevestigen'}>
                {submitted && (
                  <React.Fragment>
                    <p>De deelnemerslijst is naar de administratie gestuurd.</p>
                    <p>Wilt u toch nog een wijziging doorgeven, dan kunt u die mailen naar <TransparentAnchor href="mailto:opleidingen@rendement.nl">opleidingen@rendement.nl</TransparentAnchor>.</p>
                    {!!status.participantsComment && (
                      <label>
                        Verstuurde opmerking met betrekking tot de deelnemers:
                        <textarea value={status.participantsComment} disabled/>
                      </label>
                    )}
                  </React.Fragment>
                )}
                {!submitted && (
                  <React.Fragment>
                    {session.sessions.items.length <= 1 && (
                      <React.Fragment>
                        <p>Vul hierboven de aanwezigheid in en klik vervolgens op de button ‘Opslaan’. Daarna is het nog steeds mogelijk om wijzigingen aan te brengen.</p>
                        <p>Hoeft er niets meer gewijzigd te worden, klik dan op de button ‘Bevestigen’. Hierdoor wordt de deelnemerslijst naar de administratie gestuurd.</p>
                        <p>Wilt u toch nog een wijziging doorgeven, dan kunt u die mailen naar <TransparentAnchor href="mailto:opleidingen@rendement.nl">opleidingen@rendement.nl</TransparentAnchor>.</p>
                      </React.Fragment>
                    )}
                    {session.sessions.items.length > 1 && (
                      <React.Fragment>
                        <p>Vul hierboven per dag de aanwezigheid in en klik vervolgens op de button ‘Opslaan’. Daarna is het nog steeds mogelijk om wijzigingen aan te brengen.</p>
                        <p>Is de aanwezigheid van alle bijeenkomsten ingevuld en hoeft er niets meer gewijzigd te worden, klik dan op de button ‘Bevestigen’. Hierdoor wordt de deelnemerslijst naar de administratie gestuurd.</p>
                        <p>Wilt u toch nog een wijziging doorgeven, dan kunt u die mailen naar <TransparentAnchor href="mailto:opleidingen@rendement.nl">opleidingen@rendement.nl</TransparentAnchor>.</p>
                      </React.Fragment>
                    )}
                    <label>
                      Eventuele opmerkingen met betrekking tot de deelnemers:
                      <textarea value={this.state.note} onChange={({target: {value}}) => {
                        this.setState({note: value});
                      }}></textarea>
                    </label>
                    <CapsuleButton onClick={() => {
                      this.setState({ showConfirm: true });
                    }}>Bevestigen</CapsuleButton>
                  </React.Fragment>
                )}
              </Block>
            </div>

            {this.state.showConfirm && (
              <DialogWrapper>
                <Dialog
                  title="Deelnemers bevestigen"
                  body={`Weet u zeker dat u de aanwezigheid wilt bevestigen? U kunt hierna geen wijzigingen meer aanbrengen.`}
                  buttons={(
                    <React.Fragment>
                      {!submitted && (
                        <CapsuleButton onClick={() => {
                          submitParticipants({
                            variables: {
                              session: code,
                              note: this.state.note,
                              scheduleData: session.scheduleDataCode
                            }
                          }).then(() => {
                            refetch();
                          });
                          this.setState({ showConfirm: false });
                        }}>
                          Bevestigen
                        </CapsuleButton>
                      )}
                      <TransparentAnchor onClick={() => {
                        this.setState({ showConfirm: false });
                      }}>
                        Annuleren
                      </TransparentAnchor>
                    </React.Fragment>
                  )}
                  onClose={() => {
                    this.setState({ showConfirm: false });
                  }}
                />
              </DialogWrapper>
            )}

            {this.state.showSaved && (
              <DialogWrapper>
                <Dialog
                  title="Aanwezigheid opgeslagen"
                  body={`De aanwezigheid is opgeslagen.`}
                  buttons={(
                    <React.Fragment>
                      <TransparentAnchor onClick={() => {
                        this.setState({ showSaved: false });
                      }}>
                        Sluiten
                      </TransparentAnchor>
                    </React.Fragment>
                  )}
                  onClose={() => {
                    this.setState({ showSaved: false });
                  }}
                />
                </DialogWrapper>
            )}

          </Wrapper>
        )}
      </Mutation>
    );
  }
}
