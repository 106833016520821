import EventSessionTeaser from './event-session-teaser';
import moment from '../lib/moment';
import Block from './block';
import styled from 'styled-components';

const Year = styled.div`
  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    > li {
      list-style-type: none;
      flex-basis: 100%;
      margin-right: 0;
      margin-bottom: 15px;
    }

    @media (min-width: ${props => props.theme.breakpoints.xl}px) {
      margin: 0 0 0 15px;

      > li {
        flex-basis: 48%;
        margin-right: 2%;
        margin-bottom: 2%;
      }
    }
  }
`;

export default class EducationDateLocations extends React.Component {
  render() {
    let { eventSessions, locationInfo } = this.props;

    const { routeUrl } = this.props;

    const futureSessions = [...eventSessions];
    // .filter(session => moment(session.start).unix() > moment().unix());
    if (!futureSessions.length) {
      return null;
    }

    const renderedEventSessions = futureSessions
      .sort((a, b) => {
        // Sort by date ascending.
        return moment(a.start).unix() - moment(b.start).unix();
      })
      .reduce((bucket, session) => {
        // Group by year.
        const year = moment(session.start).format('YYYY');

        if (typeof bucket[year] === 'undefined') {
          bucket[year] = {
            year,
            eventSessions: []
          };
        }

        bucket[year].eventSessions.push(session);
        return bucket;
      }, [])
      .sort((a, b) => {
        // Sort by year ascending.
        return parseInt(a.year) - parseInt(b.year);
      })
      .map(data => {
        return (
          <Year key={data.year}>
            <h3>{data.year}</h3>
            <ul>
              {data.eventSessions.map(session => (
                <li key={session.id}>
                  <EventSessionTeaser
                    {...{
                      ...session,
                      locationInfo:
                        session.location && session.location.name
                          ? session.location.name
                          : locationInfo
                    }}
                    routeUrl={routeUrl}
                  />
                </li>
              ))}
            </ul>
          </Year>
        );
      });

    return <Block title="Alle data en locaties">{renderedEventSessions}</Block>;
  }
}
