const correlationId = (Math.random() * 1e10).toString(36).replace('.', '');

const stats = (log, body) => {
  body = body ? body : {};
  body.corr = correlationId;

  body.hostName = document.location.hostname;

  const [, mainLog, key] = log.match(/^([^.]+)(?:\.(.+))?$/);
  body.key = key;
  const url = `https://stats.agileparts.nl/record/mijnrendement/${mainLog}`;
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(body)
  }).catch(error => {
    console.error('Failed recoding stats: ' + error.message);
  });
};

export default stats;
