import { NavLink } from 'react-router-dom';
import ContentHeading from './content-heading';
import TabGroup from './tab-group';

export default () => {
  let ipLogin = false;
  const jwtPayload = (localStorage.accessToken || '').split('.')[1];
  if (jwtPayload) {
    const payload = JSON.parse(atob(jwtPayload));
    if (payload.logintype === 'iplogin') {
      ipLogin = true;
    }
  }

  if (ipLogin) {
    return (
      <div>
        <ContentHeading title=""></ContentHeading>
        <div>
          <p>
            Mijn Rendement is het persoonlijke dashboard voor gebruikers en abonnees van Rendement
            Uitgeverij. U bent ingelogd via uw IP-adres omdat de organisatie waar u voor werkt voor
            meerdere medewerkers een abonnement heeft afgesloten. U kunt daarom geen persoonlijke-
            of abonnementsgegevens wijzigen.
          </p>
          <p>
            Als u vragen hierover heeft kunt u contact opnemen met uw contactpersoon bij ons via{' '}
            <a href="mailto:contractbeheer@rendement.nl">contractbeheer@rendement.nl</a>. U kunt
            natuurlijk ook altijd telefonisch contact opnemen met onze klantenservice via 010 - 243
            3933 (op werkdagen tussen 9:00 en 17:00 uur).
          </p>
          <h2>Inloggen persoonlijk account</h2>
          <p>
            Heeft u een persoonlijk account en wilt u daar op inloggen? Volg dan de volgende
            stappen:
          </p>
          <ol>
            <li>
              Kopieer de link om persoonlijk in te loggen:
              https://idp.rendement.nl/Account/Login?skipIp=true&amp;returnUrl=https://www.rendeme
              nt.nl/gebruiker/inloggen
            </li>
            <li>
              Klik op &quot;uitloggen&quot; bovenaan Rendement.nl en Mijn Rendement. Maakt u ook
              gebruik van FutD.nl? Log ook daar dan uit.
            </li>
            <li>
              Plak de link om persoonlijk in te loggen in uw browser en voer uw persoonlijke
              gebruikersnaam en wachtwoord in.
            </li>
            <li>
              U komt nu automatisch op Rendement.nl en bent ingelogd onder uw persoonlijke account.
            </li>
          </ol>
          <h2>Aanmaken persoonlijk account</h2>
          <p>
            Wilt u een persoonlijk account aanmaken? Daarmee kunt u vanuit huis bij de abonnementen
            van uw organisatie. Het kan als volgt:
          </p>
          <ol>
            <li>
              Zorg dat u op een locatie zit met IP toegang van uw organisatie (meestal is dit op
              kantoor).
            </li>
            <li>
              Maak, met een e-mailadres bij uw organisatie (voornaam.achternaam@uworganisatie.nl kan
              wel, voornaam.achternaam@gmail.com kan niet), een account aan via het formulier op{' '}
              <a href="https://www.rendement.nl/account-aanmaken">
                https://www.rendement.nl/account-aanmaken
              </a>
              .
            </li>
            <li>Log in zoals hierboven omschreven.</li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div>
      <ContentHeading title=""></ContentHeading>
      <div>
        <p>
          Wegens een technische storing is het op dit moment niet mogelijk om uw gegevens te laden.
          We doen ons best om dit zo spoedig mogelijk te verhelpen.
        </p>

        <p>
          Heeft u vragen? Neem dan contact op met onze klantenservice via telefoonnummer:{' '}
          <a href="tel:+31102433933">010 2433933</a>.
        </p>
      </div>
    </div>
  );
};
