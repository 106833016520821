import gql from 'graphql-tag';
import filesize from 'filesize';
import {withRouter, useParams} from 'react-router-dom';

import withApolloLoader from '../with-apollo-loader';

import {compose} from '../../lib/functions';

import moment, {ISODATEFORMAT} from '../../lib/moment';

import eventMaterialQuery from '../../queries/event-material';

import EventMaterialViewer from '../../components/screens/event-material-viewer';

export default compose(withRouter, withApolloLoader({
  query: eventMaterialQuery,
  getVariables: ({match: {params: {id}}}) => ({id}),
  LoaderComponent: () => <div>Het materiaal wordt ingeladen...</div>,
  transform: ({EventScheduleData}) => {
    if (!EventScheduleData || !EventScheduleData.documents.items) {
      return null;
    }

    const {
      documentRemarks,
      documents: {items: documents = []} = {},
      loggedInParticipant: {participations: {items: participations}}
    } = EventScheduleData;

    const formatDocument = ({
      document,
      document: {size, extension = ''}
    }) => ({
      ...document,
      size: filesize(size),
      extension: extension.replace('.', '')
    });

    const eventMaterial = {
      description: documentRemarks || 'Er is geen materiaal beschikbaar.',
      documents: documents.map(formatDocument).sort((a, b) => a.name.localeCompare(b.name))
    };

    const material = [eventMaterial];

    return {material};
  }
}))(({children, material, match: {params: {id, document}}}) => {

  let doc;
  material.forEach(({documents}) => {
    documents.forEach(item => {
      if (item.documentToken === document) {
        doc = item;
      }
    });
  });

  if (!doc) {
    return <p>Document niet gevonden.</p>;
  }

  return (
    <EventMaterialViewer id={id} document={doc}>
      {children}
    </EventMaterialViewer>
  );
});
