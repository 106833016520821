import { useState, useMemo } from 'react';
import { withApollo } from 'react-apollo';
import gql from "graphql-tag";

import License from '../components/license-details';
import { userLicensesQuery } from '../queries/user-licenses';

const defaultQueryProperties = {
  refetchQueries: [{ query: userLicensesQuery }],
  awaitRefetchQueries: true
};

export default withApollo(({
  client,
  userId,
  license
}) => {
  const {
    id: licenseId,
    licensees,
    allowedDomains,
    media,
    licensesLeft
  } = license;

  const [usernameToAdd, setUsernameToAdd] = useState('');

  const addEmail = useMemo(() => () => {
    client.mutate({
      mutation: gql`mutation ($id: ID!, $email: String!, $media: String!, $userId: ID!) {
        newLicensee: createLicensee(input: {
          subscription: $id
          mail: $email
          media: $media
          status: "Pending",
          user: $userId
        }) {id}
      }`,
      variables: {
        id: licenseId,
        media,
        email: usernameToAdd,
        userId
      },
      ...defaultQueryProperties
    });

    setUsernameToAdd('');
  }, [client, license, usernameToAdd]);

  const resendInvitation = useMemo(() => email => {
    const { id, media, subscriptionId } = licensees.find(({ mail: licenseeEmail }) => email === licenseeEmail);

    client.mutate({
      mutation: gql`mutation ($realId: ID!, $id: ID!, $email: String!, $media: String!, $userId: ID!) {
        updatedLicensee: updateLicensee(id: $realId, input: {
          mail: $email
          media: $media
          subscription: $id
          status: "Resend"
          user: $userId
        }) {id}
      }`,
      variables: {
        realId: id,
        id: licenseId,
        media,
        email,
        subscriptionId,
        userId
      },
      ...defaultQueryProperties
    });
  }, [client, license]);

  const changeMedia = useMemo(() => (id, newMedia) => {
    const { id: licenseId, email, subscriptionId, status } = licensees.find(({ id: licenseeId }) => id === licenseeId);

    client.mutate({
      mutation: gql`mutation ($id: ID!, $media: String!, $userId: ID!) {
        updatedLicensee: updateLicensee(id: $id, input: {
          mail: $email
          media: $media
          subscription: $subscriptionId
          status: $status,
          user: $userId
        }) {id}
      }`,
      variables: {
        id: licenseId,
        media: newMedia,
        email,
        subscriptionId: id,
        status,
        userId
      },
      ...defaultQueryProperties
    });
  }, [client, license]);

  const removeLicensee = useMemo(() => id => {
    client.mutate({
      mutation: gql`mutation ($id: ID!) {
        deleteLicensee(id: $id) {id}
      }`,
      variables: { id },
      ...defaultQueryProperties
    });
  });

  return (
    <License {...{
      ...license,
      userId,
      usernameToAdd,
      setUsernameToAdd,
      addEmail,
      resendInvitation,
      changeMedia,
      removeLicensee
    }} />
  );
});
