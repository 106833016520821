const productsWithLicenses = [
  'ARBOPROF',
  'ARBOPROF+',
  'BESTUUPROF+',
  'BSTPROF',
  'BVPROF',
  'FAF',
  'FAPROF',
  'FAPROF+',
  'FISPROF',
  'FISPROF+',
  'FNDTOT',
  'FOA',
  'FOAD',
  'FOF',
  'FOL',
  'FOM',
  'FOMS',
  'FON',
  'FOP',
  'FOS',
  'FUPA',
  'HRPROF',
  'HRPROF+',
  'MANAGEPROF+',
  'MTPROF',
  'OFFPROF',
  'ORPROF',
  'ORPROF+',
  'POA',
  'POAD',
  'POF',
  'POM',
  'POP',
  'POS',
  'RENPROF+',
  'SALPROF',
  'SALPROF+',
  // FUTD titels (support #262).
  'BALANS',
  'BELOBELA',
  'DOUANE',
  'FISCOLOOG',
  'FISPRAK',
  'FUTDB',
  'FUTDP',
  'GRENS',
  'HUUR',
  'JURIDISCH',
  // Nieuwe titels (mail Jeroen 2023-08-23).
  'ARBOPROFTOT',
  'BELANG',
  'BESTUURPROFTOT',
  'FAPROFTOT',
  'FISPROFTOT',
  'FNDTOT',
  'HRPROFTOT',
  'MANAGEPROFTOT',
  'ORPROFTOT',
  'RENPROFTOT',
  'SALPROFTOT',
  // Mail Jeroen 2024-04-08
  'MANAGEPROF'
];

export const hasLicenses = (subscriptionId) => {
  const productCode = subscriptionId.split('---')[0];
  return productsWithLicenses.indexOf(productCode) >= 0;
};
