'use strict';

import {withApollo} from 'react-apollo';
import gql from 'graphql-tag';
import {graphql} from 'react-apollo';
import PersonalDetails from '../../components/screens/personal-details';

const query = gql`query {
  User: currentUser {
    id
    initials
    salutation
    lastName
    phone
    mail
    invoicemail
    eventMail
    firstName
    infix
    functionName
    companyName
    avatar {
      items {
        id
        data
      }
    }
  }
}`;

@withApollo
@graphql(query, {
  props: ({data: {User}, ownProps: {client}}) => ({
    User,
    client,
    avatar: (User && User.avatar && User.avatar.items.length ? User.avatar.items[0].data : false)
  })
})
export default class extends React.Component {
  render() {
    if (!this.props.User || typeof this.props.User === 'undefined') {
      return <div><p>Uw gegevens worden ingeladen...</p></div>;
    }

    let accountType = 'Person';
    let payload = {};
    const jwtPayload = (localStorage.accessToken || '').split('.')[1];
		if (jwtPayload) {
			payload = JSON.parse(atob(jwtPayload));
			if (payload.accountType) {
				accountType = payload.accountType;
			}
		}

    const {
      ...userProps
    } = this.props.User;

    const {avatar} = this.props;

    const value = {
      ...payload,
      ...userProps,
      avatar
    };

    return <PersonalDetails value={value} accountType={accountType} />;
  }
}
