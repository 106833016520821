'use strict';

import TeacherSessionMaterials from './teacher-session-materials';

import Block from '../block';

import styled from 'styled-components';

const Wrapper = styled.div`
    .form-files {
        background-color: white;
        border-radius: 20px;
        padding: 20px;

        button {
            padding: 20px 200px;
            @media (max-width: ${props => props.theme.breakpoints.small}px) {
              padding: 20px 140px;
            }
        }
    }
`;

const Container = styled.div`
p {
  color: ${props => props.theme.darkGray};
}
.upload-drop {
  button {
    padding: 20px 200px;
    @media (max-width: ${props => props.theme.breakpoints.small}px) {
      padding: 20px 140px;
    }
  }
}

table {
  thead {
    font-size: 0;
  }
  @media (max-width: ${props => props.theme.breakpoints.small}px) {
    td {
      display: block;
    }
  }
  td:nth-child(2) {
    min-width: 300px;
  }
  td {
    border-bottom: none;
  }
}

`;

export default class extends React.Component {
  render() {
    return (
      <Wrapper>
        <TeacherSessionMaterials
          title="Boekenlijst"
          session={this.props.session}
          multiple={false}
          type="BookList"
          refetch={this.props.refetch}
          code={this.props.code}
        />
        <div style={{marginTop: '25px'}}>
          <TeacherSessionMaterials
            title="Materiaal"
            {...this.props}
          />
        </div>
      </Wrapper>);
  }
}
