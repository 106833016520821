'use strict';

import { NavLink, Route } from 'react-router-dom';
import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';

import SubscriptionDetail from './subscription-detail';
import SubscriptionUpdate from './subscription-update';
import SubscriptionCancel from './subscription-cancel';
import SubscriptionAccounts from '../../components/screens/subscription-accounts';
import UserSubscriptionQuery from '../../queries/user-subscription';
import { hasLicenses } from '../../lib/subscriptions';

import ContentHeading from '../../components/content-heading';
import TabGroup from '../../components/tab-group';

@withRouter
@graphql(UserSubscriptionQuery, {
  props: ({ data: { UserSubscription, User, loading }, ownProps: { match: { params: { id: userSubscriptionId } } } }) => ({
    ...UserSubscription,
    user: User,
    loading,
    userSubscriptionId
  }),
  options: ({ match: { params: { id: userSubscriptionId } } }) => ({ variables: { userSubscriptionId } })
})
export default class extends React.Component {
  render() {
    if (this.props.loading) {
      return null;
    }

    const {
      title,
      isOwner,
      match: {
        params: {
          title: urlTitle,
          id
        }
      },
    } = this.props;

    const url = `/producten/abonnementen/${urlTitle}/${id}`;

    const showLicenses = isOwner && hasLicenses(id);

    return (
      <div>
        <ContentHeading title={this.props.title}>
          <TabGroup>
            <NavLink
              activeClassName="active"
              exact
              to={`${url}`}
            >
              Gegevens
            </NavLink>
            {showLicenses && (
              <NavLink
                activeClassName="active"
                exact
                to={`${url}/licenties`}
              >
                Licenties
              </NavLink>
            )}
          </TabGroup>
        </ContentHeading>
        <Route
          exact
          path="/producten/abonnementen/:title/:id"
          component={SubscriptionDetail}
        />
        <Route
          exact
          path="/producten/abonnementen/:title/:id/wijzigen"
          component={SubscriptionUpdate}
        />
        <Route
          exact
          path="/producten/abonnementen/:title/:id/opzeggen"
          component={SubscriptionCancel}
        />
        {showLicenses && (
          <Route
            exact
            path="/producten/abonnementen/:title/:id/licenties"
            component={SubscriptionAccounts}
          />
        )}
      </div>
    );
  }
}
