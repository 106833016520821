import EducationUpcomingSession from '../components/education-upcoming-session';
import gql from 'graphql-tag';
import moment from '../lib/moment';
import {graphql} from 'react-apollo';
import {withRouter} from 'react-router-dom'

@withRouter
@graphql(gql `query($id: ID!) {
  EventScheduleData(id: $id) {
    id
    eventLocation {
      id
      name
      street
      houseNumber
      houseNumberSupplement
      city
      postalCode
      routeUrl
    }
		eventSessions: sessions {
      items {
				eventTeacher {
          id
					name
				}
        start
        end
        id
        locationInfo: location
      }
    }
  }
}`, {
  props: ({data}) => {
    if (!data.loading && typeof data.EventScheduleData !== 'undefined') {
      const eventSessions = data.EventScheduleData.eventSessions.items;
      const futureSessions = eventSessions.filter(
        // Filter out events in the past.
        session => moment(session.start).unix() > moment().unix()
      ).sort((a, b) => { // Sort by date ascending.
        return a.start.localeCompare(b.start);
      });

      const isMultipleDays = eventSessions.length > 1;
      const eventSession = futureSessions.length
        ? eventSessions[0]
        : (
          isMultipleDays ? null : eventSessions[0]
        );

      return {
        location: data.EventScheduleData.eventLocation,
        eventSession,
        isMultipleDays
      };
    }
  },
  options: ({match: {params: {id}}}) => ({variables: {id}})
})
export default class extends React.Component {
  render() {
    if (!this.props.eventSession) {
      return <div/>;
    }
    let street = this.props.location.street;
    if (this.props.location.houseNumber) {
      street = `${street} ${this.props.location.houseNumber}`;
    }
    if (this.props.location.houseNumberSupplement) {
      street = `${street} ${this.props.location.houseNumberSupplement}`;
    }

    return <EducationUpcomingSession
      title={this.props.isMultipleDays ? "Eerstvolgende bijeenkomst" : "Klassikale bijeenkomst"}
      start={this.props.eventSession.start}
      end={this.props.eventSession.end}
      eventTeacher={this.props.eventSession.eventTeacher}
      locationInfo={this.props.location.name}
      postalCode={this.props.location.postalCode}
      address={street}
      city={this.props.location.city}
      routeUrl={this.props.location.routeUrl}
    />
  }
}
