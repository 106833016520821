'use strict';

import { graphql } from 'react-apollo';
import { withRouter } from 'react-router-dom';
import SubscriptionDetail from '../../components/screens/subscription-detail';
import UserSubscriptionQuery from '../../queries/user-subscription';

const queryOptions = ({ match: { params: { id: userSubscriptionId } } }) => ({ variables: { userSubscriptionId } });

@withRouter
@graphql(UserSubscriptionQuery, {
  props: ({ data: { UserSubscription, User, loading }, ownProps: { match: { params: { id: userSubscriptionId } } } }) => ({
    ...UserSubscription,
    user: User,
    loading,
    userSubscriptionId
  }),
  options: queryOptions
})
export default class extends React.Component {
  render() {
    if (this.props.loading) {
      return null;
    }

    const {
      title,
      active,
      match: { url },
      user,
      isOwner,
      startDate,
      endDate,
      billingAddress,
      shippingAddress
    } = this.props;

    const billing = billingAddress && billingAddress.city ? billingAddress : user.billingAddress;

    return <SubscriptionDetail
      title={title}
      url={url}
      active={active}
      user={user}
      startDate={startDate}
      endDate={endDate}
      billingAddress={billing}
      shippingAddress={shippingAddress}
      isOwner={isOwner}
    />;
  }
}
